<template>
  <v-container>
    <div class="container-global">
      <div class="header-detail">
        <h3 class="mb-0">Crear restaurante</h3>
        <div>
          <router-link class="btn-back" :to="{name: 'Restaurantes'}">
            <img
              class="icon-arrow-left"
              :src="require('@/assets/arrow-left.svg')"
              alt="icon arrow-left"
            />
            Regresar
          </router-link>
        </div>
      </div>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="sendDataRestaurante()"
        lazy-validation
        class="mb-6"
      >
        <v-row class="mb-8">
          <v-col col="12" sm="12" md="6">
            <v-text-field
              color="primary"
              v-model="nombre"
              :rules="nameRules"
              label="Nombre"
              :change="nombreRestaurante()"
              required
            ></v-text-field>
            <v-text-field color="primary" v-model="slug" disabled label="Slug"></v-text-field>
            <p class="primary--text font-weight-bold">Logo del restaurante</p>
            <div
              class="drag-inputFile mb-8"
              @drop.stop.prevent="onDropFileLogo($event)"
              @dragover.prevent
              @dragenter.prevent
            >
              <label for="input-file" class="select"></label>
              <input
                @change="changeFileLogo($event)"
                id="input-file"
                class="input-file"
                accept=".png, .jpg"
                type="file"
              />
              <div
                class="img-preview"
                v-if="urlTemporal"
                v-bind:style="{ 'background-image': 'url(' + urlTemporal + ')' }"
              ></div>
              <v-icon small class="editBtn" v-if="urlTemporal">mdi-pencil</v-icon>
              <v-icon small class="deleteBtn" @click="removeFileLogo()" v-if="urlTemporal">mdi-delete</v-icon>
              <div class="upload">
                <img class="icon-camara" :src="require('@/assets/camara.svg')" alt="icon camara" />
                <p class="label-black">Seleccionar archivo</p>
                <p class="label-instruccion">o arrastra una imagen aquí.</p>
              </div>
            </div>
            <p class="primary--text font-weight-bold">Imagen principal</p>
            <div
              class="drag-inputFile mb-8"
              @drop.stop.prevent="onDropImagenPrincipal($event)"
              @dragover.prevent
              @dragenter.prevent
            >
              <label for="file-img-principal" class="select"></label>
              <input
                @change="changeFileImagenPrincipal($event)"
                id="file-img-principal"
                class="input-file"
                accept=".png, .jpg"
                type="file"
              />
              <div
                class="img-preview"
                v-if="urlTemporalPrincipal"
                v-bind:style="{ 'background-image': 'url(' + urlTemporalPrincipal + ')' }"
              >
              </div>
              <v-icon small class="editBtn" v-if="urlTemporalPrincipal">mdi-pencil</v-icon>
              <v-icon small class="deleteBtn" @click="removeFileImagePrincipal()" v-if="urlTemporalPrincipal">mdi-delete</v-icon>
              <div class="upload">
                <img class="icon-camara" :src="require('@/assets/camara.svg')" alt="icon camara" />
                <p class="label-black">Seleccionar archivo</p>
                <p class="label-instruccion">o arrastra una imagen aquí.</p>
              </div>
            </div>
            <p class="primary--text font-weight-bold mb-0">Fondo</p>
            <v-select
              :items="opcionesFondo"
              v-model="tipoFondo"
              :rules="tipoFondoRules"
              color="primary"
              label="Elige una opción"
              required
              class="mb-4"
            ></v-select>
            <v-color-picker
              v-show="tipoFondo == 'color'"
              dot-size="20"
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              v-model="colorFondo"
              class="mb-4"
            ></v-color-picker>
            <div
              v-show="tipoFondo == 'imagen'"
              class="drag-inputFile mb-8"
              @drop.stop.prevent="onDropFileFondo($event)"
              @dragover.prevent
              @dragenter.prevent
            >
              <label for="file-img-fondo" class="select"></label>
              <input
                @change="changeFileFondo($event)"
                id="file-img-fondo"
                class="input-file"
                accept=".png, .jpg"
                type="file"
              />
              <div
                class="img-preview"
                v-if="urlFondoTemporal"
                v-bind:style="{ 'background-image': 'url(' + urlFondoTemporal + ')' }"
              >
              </div>
              <v-icon small class="editBtn" v-if="urlFondoTemporal">mdi-pencil</v-icon>
              <v-icon small class="deleteBtn" @click="removeFileFondo()" v-if="urlFondoTemporal">mdi-delete</v-icon>
              <div class="upload">
                <img class="icon-camara" :src="require('@/assets/camara.svg')" alt="icon camara" />
                <p class="label-black">Seleccionar archivo</p>
                <p class="label-instruccion">o arrastra una imagen aquí.</p>
              </div>
            </div>
            <p class="primary--text font-weight-bold">Color de la barra de navegación</p>
            <v-color-picker
              dot-size="20"
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              v-model="colorBar"
              class="mb-4"
            ></v-color-picker>
            <p class="primary--text font-weight-bold">Color de texto principal</p>
            <v-color-picker
              dot-size="20"
              hide-mode-switch
              mode="hexa"
              swatches-max-height="200"
              v-model="colorTextoPrincipal"
            ></v-color-picker>
          </v-col>
        </v-row>
        <v-btn
          color="buttonPrimary"
          class="white--text pr-8 pl-8 btn-margin"
          :disabled="!valid"
          :loading="loading"
          type="submit"
        >Guardar</v-btn>
      </v-form>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CrearRestaurante",
  data: () => ({
    valid: false,
    nombre: "",
    imagenLogo: null,
    imagenFondo: null,
    imagenPrincipal: null,
    slug: "",
    colorTextoPrincipal: "",
    tipoFondo: "",
    colorFondo: "",
    colorBar: "",
    opcionesFondo: ["color", "imagen"],
    urlTemporal: "",
    urlFondoTemporal: "",
    urlTemporalPrincipal: "",
    nameRules: [v => !!v || "El nombre es requerido"],
    tipoFondoRules: [v => !!v || "El fondo es requerido"],
    fileRules: [
      v => !!v || "La imagen es requerida",
      v => (v && v.size > 0) || "La imagen es requerida"
    ],
    loading: false
  }),
  methods: {
    ...mapActions(["creaRestaurante"]),
    async sendDataRestaurante() {
      const uuidGenerate = this.createUUID();
      // se validan los campos
      if (!this.$refs.form.validate()) return;
      const restauranteCreate = {
        nombre: this.nombre,
        foto: this.imagenLogo,
        imgPrincipal: this.imagenPrincipal,
        slug: this.slug + "-" + uuidGenerate,
        fotoFondo: this.imagenFondo,
        colorTextoPrincipal: this.colorTextoPrincipal,
        colorBar: this.colorBar,
        tipoFondo: this.tipoFondo,
        colorFondo: this.colorFondo
      };
      this.loading = true;
      const result = await this.creaRestaurante(restauranteCreate);
      if (result.res) {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "success",
          timerProgressBar: true,
          title: "Se agregó correctamente"
        });
        this.limpiaDatosImages();
        this.reset();
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: result.error
        });
      }
      this.loading = false;
    },
    createUUID() {
      let uuid = Math.random().toString(36).slice(-6);
      return uuid;
    },
    limpiaDatosImages() {
      this.imagenLogo = null;
      this.imagenFondo = null;
      this.imagenPrincipal = null;
      this.urlTemporal = "";
      this.urlFondoTemporal = "";
      this.urlTemporalPrincipal = "";
    },
    onDropFileLogo(event) {
      const file = event.dataTransfer.files[0];
      this.processFileLogo(file);
    },
    changeFileLogo(event) {
      const file = event.target.files[0];
      this.processFileLogo(file);
    },
    processFileLogo(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.imagenLogo = file;
            this.imageProcess(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb"
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg"
        });
      }
    },
    imageProcess(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.urlTemporal = e.target.result;
        };
      } else {
        this.urlTemporal = require("@/assets/default.png");
      }
    },
    removeFileLogo() {
      this.urlTemporal = "";
      this.imagenLogo = "";
    },
    onDropImagenPrincipal(event) {
      const file = event.dataTransfer.files[0];
      this.processImagenPrincipal(file);
    },
    changeFileImagenPrincipal(event) {
      const file = event.target.files[0];
      this.processImagenPrincipal(file);
    },
    processImagenPrincipal(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.imagenPrincipal = file;
            this.imageProcessImagenPrincipal(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb"
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg"
        });
      }
    },
    imageProcessImagenPrincipal(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.urlTemporalPrincipal = e.target.result;
        };
      } else {
        this.urlTemporalPrincipal = require("@/assets/default.png");
      }
    },
    removeFileImagePrincipal() {
      this.urlTemporalPrincipal = "";
      this.imagenPrincipal = "";
    },
    onDropFileFondo(event) {
      const file = event.dataTransfer.files[0];
      this.processFileFondo(file);
    },
    changeFileFondo(event) {
      const file = event.target.files[0];
      this.processFileFondo(file);
    },
    processFileFondo(file) {
      var ext = file.name.split(".").pop();
      if (ext == "png" || ext == "jpg") {
        if (file.size <= 512017) {
          if (file) {
            this.imagenFondo = file;
            this.imageProcessFondo(file);
          }
        } else {
          this.$swal({
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            timerProgressBar: true,
            title: "El tamaño maximo permitido es de 500kb"
          });
        }
      } else {
        this.$swal({
          showConfirmButton: false,
          timer: 3000,
          icon: "error",
          timerProgressBar: true,
          title: "Solo se permiten archivos de tipo .png, .jpg"
        });
      }
    },
    imageProcessFondo(file) {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = e => {
          this.urlFondoTemporal = e.target.result;
        };
      } else {
        this.urlFondoTemporal = require("@/assets/default.png");
      }
    },
    removeFileFondo() {
      this.urlFondoTemporal = "";
      this.imagenFondo = "";
    },
    reset() {
      this.$refs.form.reset();
    },
    nombreRestaurante() {
      if (this.nombre == null) return;
      var slug = this.nombre
        .toLowerCase()
        .replace(/[^\w ]+/g, "")
        .replace(/ +/g, "-");
      this.slug = slug;
    }
  }
};
</script>
<style lang="scss">
.header-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 16px;
  .btn-back {
    margin-right: 35px;
  }
  .float-paso {
    color: #a7a7a7;
    position: absolute;
    right: 0;
    bottom: -60px;
    margin-bottom: 0;
    font-weight: normal;
  }
}
</style>